import { Config, Persistence } from 'mixpanel-browser';

export type MixpanelAnalyticsEnvironments = 'PRD' | 'HOM' | 'LOCAL';

export interface MixpanelAnalyticsConfig {
  platform: string;
  environment: MixpanelAnalyticsEnvironments;
  token: string;
  configuration: Partial<Config>;
}

const parseBoolean = (value?: string) => value === 'true';

export const MixpanelAnalyticsConfig: MixpanelAnalyticsConfig = {
  platform: process.env.NEXT_PUBLIC_MIXPANEL_PLATFORM || 'storefront',
  environment: (process.env.NEXT_PUBLIC_MIXPANEL_ENVIRONMENT as MixpanelAnalyticsEnvironments) || 'HOM',
  token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '',
  configuration: {
    debug: parseBoolean(process.env.NEXT_PUBLIC_MIXPANEL_CONFIG_DEBUG) || false,
    track_pageview: parseBoolean(process.env.NEXT_PUBLIC_MIXPANEL_TRACK_PAGEVIEW) || false,
    persistence: (process.env.NEXT_PUBLIC_MIXPANEL_PERSISTENCE as Persistence) || 'localStorage',
  },
};
