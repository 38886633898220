import mixpanel from 'mixpanel-browser';
import { createContext, useContext, useEffect } from 'react';
import { AuthenticationContext } from './Authentication';
import { MixpanelAnalyticsConfig } from '~/config/MixpanelAnalyticsConfig';

class MixpanelAnalytics {
  private config = MixpanelAnalyticsConfig;
  private username!: string;
  private email!: string;
  private _userIsLoggedIn!: boolean;
  private queue: { event: string; parameters: Record<string, unknown> }[];

  constructor() {
    this.queue = [];
    mixpanel.init(this.config.token, this.config.configuration);
  }

  set userIsLoggedIn(isLoggedIn: boolean) {
    this._userIsLoggedIn = isLoggedIn;
  }

  get userIsLoggedIn() {
    return this._userIsLoggedIn;
  }

  click(componentName: string, customParameters: Record<string, unknown> = {}) {
    const normalized = 'click_' + this.normalize(componentName);
    const parameters = this.normalizeParameters(normalized, customParameters);
    this.send(normalized, parameters);
  }

  view(pageName: string, customParameters: Record<string, unknown> = {}) {
    const normalized = 'view_' + this.normalize(pageName);
    const parameters = this.normalizeParameters(normalized, customParameters);
    this.send(normalized, parameters);
  }

  callback(requestName: string, customParameters: Record<string, unknown> = {}) {
    const normalized = 'callback_' + this.normalize(requestName);
    const parameters = this.normalizeParameters(normalized, customParameters);
    this.send(normalized, parameters);
  }

  error(status: number, request: Record<string, unknown>, response: Record<string, unknown>) {
    const normalized = `error_${status || 500}`;
    const parameters = this.normalizeParameters(normalized, { request, response });
    this.send(normalized, parameters);
  }

  identifyUser(username: string, email: string) {
    this.username = username;
    this.email = email;
  }

  proccessQueue() {
    if (this.queue.length <= 0) return;
    const item = this.queue.pop() as { event: string; parameters: Record<string, unknown> };
    this.send(item?.event, item?.parameters);
    this.proccessQueue();
  }

  private send(event: string, parameters: Record<string, unknown>): void {
    if (!this.userIsLoggedIn) return;
    if (!this.username || !this.email) {
      this.queue.push({ event, parameters });
      return;
    }
    if (!this.config.token) {
      console.debug('AnalyticsService', { event, parameters, username: this.username, email: this.email });
      return;
    }
    mixpanel.identify(this.email);
    mixpanel.people.set({ $name: this.username, $email: this.email });
    mixpanel.track(event, { ...parameters });
  }

  private normalize(str: string): string {
    const separatorCaracter = '_';
    return str.toLowerCase().replace(/[-\s]/g, separatorCaracter);
  }

  private normalizeParameters(event: string, parameters: Record<string, unknown>): Record<string, unknown> {
    const normalized: Record<string, unknown> = {};
    const { environment, platform } = this.config;
    const excludedKeys = ['population'];

    Object.keys(parameters).forEach((key) => {
      if (excludedKeys.includes(key)) return (normalized[key] = parameters[key]);

      const normalizedKey = `${event}_${this.normalize(key)}`;
      normalized[normalizedKey] = parameters[key];
    });

    return { ...normalized, environment, platform };
  }
}

const mixpanelAnalytics = new MixpanelAnalytics();

const MixpanelAnalyticsContext = createContext(mixpanelAnalytics);

export const useMixpanelAnalytics = () => {
  const { provider, user, isLoggedIn } = useContext(AuthenticationContext);
  useEffect(() => {
    mixpanelAnalytics.userIsLoggedIn = isLoggedIn;
  }, [isLoggedIn]);

  useEffect(() => {
    if (provider?.companyName && user?.email) {
      mixpanelAnalytics.identifyUser(provider.companyName, user.email);
      mixpanelAnalytics.proccessQueue();
    }
  }, [provider, user]);

  return useContext(MixpanelAnalyticsContext);
};

export function MixpanelAnalyticsProvider({ children }) {
  return <MixpanelAnalyticsContext.Provider value={mixpanelAnalytics}>{children}</MixpanelAnalyticsContext.Provider>;
}
