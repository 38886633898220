import 'react-credit-cards/es/styles-compiled.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '@objectedge/pecai-storefront-ds/src/styles/common.scss';
import '~/styles/global.scss';

import { apiPlugin, storyblokInit } from '@storyblok/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import { AlertsProvider } from '~/components/context/AlertsProvider';
import { Apollo } from '~/components/context/Apollo';
import { Authentication } from '~/components/context/Authentication';
import { LoginAndRegisterModalProvider } from '~/components/context/LoginAndRegisterModalProvider';
import { EventProvider } from '~/components/context/Event';
import ConfirmationProvider from '~/components/structure/providers/ConfirmationProvider';
import BugsnagErrorBoundary from '~/connectors/bugsnag';
import { CartProvider } from '~/utils/cart';
import { GTM } from '~/utils/gtm';
import { MixpanelAnalyticsProvider } from '~/components/context/MixpanelAnalytics';

storyblokInit({
  accessToken: process.env.STORYBLOK_TOKEN,
  use: [apiPlugin],
});

Router.events.on('routeChangeComplete', (url) => {
  if (!process.browser || !localStorage) return;
  // Store the last 10 routes navigated on localstorage
  // This is meant to be used to move user back to specific pages by navigation component
  const track = JSON.parse(localStorage.getItem('previousUrl') || '[]');
  track.unshift(url);
  localStorage.setItem('previousUrl', JSON.stringify(track.slice(0, 10)));
  GTM.pageView(url);
});

interface PecaiAppProps extends Omit<AppProps, 'pageProps'> {
  pageProps: AppProps['pageProps'] & { maintenance: Boolean; initialApolloState: any };
}

const PecaiApp = ({ Component, pageProps }: PecaiAppProps) => {
  const { asPath } = useRouter();
  const canonicalUrl = process.env.COMMERCE_URL + asPath.split('?')[0];

  return (
    <BugsnagErrorBoundary>
      <EventProvider>
        <Apollo state={pageProps?.initialApolloState ?? {}}>
          <Authentication>
            <LoginAndRegisterModalProvider>
              <AlertsProvider>
                <ConfirmationProvider>
                  <CartProvider>
                    <MixpanelAnalyticsProvider>
                      <Head>
                        {/* Adding here to avoid duplications as per link below
                          https://github.com/vercel/next.js/issues/6919#issuecomment-500471583 */}
                        <link key="link-canonical" rel="canonical" href={canonicalUrl} />
                      </Head>
                      <Component {...pageProps} />
                    </MixpanelAnalyticsProvider>
                  </CartProvider>
                </ConfirmationProvider>
              </AlertsProvider>
            </LoginAndRegisterModalProvider>
          </Authentication>
        </Apollo>
      </EventProvider>
    </BugsnagErrorBoundary>
  );
};

export default PecaiApp;
