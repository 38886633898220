import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import cookie from 'js-cookie';
import { useContext, useMemo } from 'react';
import { Event, EventContext } from '~/components/context/Event';
import cache from '~/state/cache';
import { bugsnagMiddleware } from './bugsnag';
import { getAuthCookie } from '~/utils/cookie';

const STORE_REFERENCE = process.env.PARTS_STORE_REFERENCE || 'parts';
const COOKIE_PROVIDER_ID = process.env.COOKIE_PROVIDER_ID || 'providerId';

let apolloClient: ApolloClient<InMemoryCache | NormalizedCacheObject>;

const httpLink = new HttpLink({
  uri: process.browser ? process.env.NEXT_PUBLIC_GRAPHQL_URL : process.env.GRAPHQL_URL,
  credentials: 'omit',
});

const persistedQueriesLink = createPersistedQueryLink({
  useGETForHashedQueries: true,
});
const authMiddleware = new ApolloLink((operation, forward) => {
  const context = operation.getContext();
  const token = getAuthCookie();
  const selectedProviderId = cookie.get(COOKIE_PROVIDER_ID);
  const pacid = cookie.get('PACID');

  let headers = { ...context?.headers, store: STORE_REFERENCE };

  if (token) {
    headers = { ...headers, authorization: `Bearer ${token}`, selectedProviderId, pacid };
  }

  operation.setContext({
    headers: { ...headers },
  });

  return forward(operation);
});

const headerInjectionMiddleware = new ApolloLink((operation, forward) => {
  const ctx = operation.getContext();

  if (ctx.captcha)
    operation.setContext({ headers: { ...ctx.headers, 'g-recaptcha-response': ctx.captcha, store: STORE_REFERENCE } });

  return forward(operation);
});

function createApolloClient(pub): ApolloClient<NormalizedCacheObject> {
  const errorLink = onError(({ graphQLErrors }) => {
    if (!pub || !graphQLErrors) return;

    // Find graphql authorization errors
    // This can mean the user lost access and his/her token should be erased
    const err = graphQLErrors.find((e) => e.message === "The current user isn't authorized.");

    // Find graphql unauthorized cart errors
    // when the token expires and the logged user cart id is still in the localStorage
    const unauthorizedCartErrors = graphQLErrors.find(
      (e) =>
        e.message === "The cart isn't active." ||
        e.message.includes('The current user cannot perform operations on cart') ||
        e.message.includes(`The selected provider isn't authorized.`) ||
        e.message.includes('Not authorized')
    );

    if (err || unauthorizedCartErrors) pub(Event.APOLLO_UNAUTHORIZED_ACCESS, err);
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
      bugsnagMiddleware,
      errorLink,
      authMiddleware,
      headerInjectionMiddleware,
      persistedQueriesLink as any,
      httpLink,
    ]),
    cache,
  });
}

export function initializeApollo(
  initialState: any = null,
  pub: any = null
): ApolloClient<InMemoryCache | NormalizedCacheObject> {
  const _apolloClient = createApolloClient(pub);

  // If the page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any): ApolloClient<InMemoryCache | NormalizedCacheObject> {
  const { pub } = useContext(EventContext);

  const store = useMemo(() => initializeApollo(initialState, pub), [initialState]);
  return store;
}
